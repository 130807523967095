import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { saasTheme } from 'common/src/theme/saas';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/Saas/saas.style';
import Navbar from '../containers/Saas/Navbar';
import Footer from '../containers/Saas/Footer';
import CallToActionBottom from '../containers/Saas/TrialSection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import SEO from '../components/seo';
import Layout from '../components/layout';
import PrivacySection from '../containers/Saas/appPrivacyPolicy';

export default () => {
  return (
    <ThemeProvider theme={saasTheme}>
      <Fragment>
        <SEO title="SalesOcean | App privacy policy" />
        <ResetCSS />
        <GlobalStyle />
        <Layout>
          <ContentWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar />
              </DrawerProvider>
            </Sticky>
            <PrivacySection />
            <CallToActionBottom />
            <Footer />
          </ContentWrapper>
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
